<template>
    <div class="searchCont"  >
      <van-nav-bar title="搜索结果"  >
        <template #left>
          <van-icon name="arrow-left" color="#141212" @click="$router.go(-1)" :size="16" slot="left" />
        </template>
      </van-nav-bar>
      <div class="tiket_list" v-if="list&&list.length">
        <van-pull-refresh  v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="tiket_list_item" v-for="item in list" @click="routerPush(item)">
              <van-image :src="item.cover">
                <template v-slot:error>加载失败</template>
              </van-image>

              <div class="tiket_list_item_right">
                <p>{{item.bizName}}</p>
                <div class="tiket_list_item_right_text">
                  {{textFunc(item.introduce)}}
                </div>
                <!--<div class="tiket_list_item_right_img">-->
                  <!--<img src="./img/juli.png" alt="">-->
                  <!--距您  12.03km-->
                <!--</div>-->
                <div class="tiket_list_item_right_rate">
                  <span>{{item.bizTypeDesc}}</span>
                  <!--<p>-->
                    <!--<span>￥160</span>-->
                    <!--<span>起</span>-->
                  <!--</p>-->
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <div class="searchContcont" v-else>
        <img src="./img/noall.png" alt="">
        <span>暂无数据</span>
        <img style="width:332px;height: 26px" class="bgbottom" src="./img/bg.png" alt="">
      </div>

    </div>
</template>

<script src="./searchCont.js">

</script>

<style scoped src="./searchCont.css">

</style>
